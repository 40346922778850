import React from 'react';
import { NavRoutes, QuotePages, ValidationMessages } from 'common/utils';
import { CheckboxButton, QuoteContainer } from 'components/cpaipw';
import { FormContext } from 'quote/FormContext';
import { PolicyReviewItem } from './PolicyReviewItem';
import { PolicyActionContext, PolicyDataContext } from './PolicyContext';
import { Button } from 'components/buttons';
import { useHistory } from 'react-router-dom';
import { PoliciesIds, sortProducts } from './utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import oliverAPI from 'api/OliverAPI';
import { ReactComponent as IconLoad } from 'assets/icons/icon-load.svg';

interface IOptIn {
	optIn: boolean;
	email: string;
	sendPDFByEmail: boolean;
}

const ReviewSelection: React.FC = () => {
	const FormCtx = React.useContext(FormContext);
	const PolicyData = React.useContext(PolicyDataContext);
	const PolicyAction = React.useContext(PolicyActionContext);
	const history = useHistory();
	const quoteData = PolicyAction.createQuoteData(PolicyData.quoteData);
	const hasChildCI = PolicyData.hasChildCI;
	const spouseQuoteData = PolicyAction.createQuoteData(PolicyData.spouseQuoteData);
	const total = PolicyData.quoteData.total;
	const spouseTotal = PolicyData.spouseQuoteData.total;
	const methods = useForm<IOptIn>({
		mode: 'onSubmit',
		defaultValues: {
			optIn: FormCtx.initialUserData?.optIn,
			email: FormCtx.initialUserData?.email || ''
		},
		reValidateMode: 'onChange'
	});
	const { control, errors, handleSubmit } = methods;
	React.useEffect(() => {
		FormCtx.setCurrentPage(QuotePages.applyForCoverage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [emailSent, setEmailSent] = React.useState(false);
	const [emailError, setEmailError] = React.useState<string>();
	const [emailSendError, setEmailSendError] = React.useState<string>();

	const handleEmailReport = async (values: IOptIn) => {
		FormCtx.setIsLoading(true);
		if (emailSendError !== undefined) {
			setEmailSendError(undefined);
		}

		try {
			if (values.email) {
				updateContactDetails(values);
				const combinedValues = {
					...FormCtx.initialUserData,
					...FormCtx.personalData,
					...FormCtx.spouseData,
					...FormCtx.needsAssessment,
					...values,
					sendPDFByEmail: true
				};
				await FormCtx.upsertFormValues(combinedValues);
				const hasSpousePolicy = PolicyData.spousePolicy.length > 0;
				const is_pdf_sent = await oliverAPI.postReport({ applicant_id: FormCtx.applicantId, spouse_applicant_id: FormCtx.spouseApplicantId, has_spouse_policy: hasSpousePolicy });
				setEmailSent(is_pdf_sent);
				if (!is_pdf_sent) {
					setEmailSendError('There was a problem sending the email. Please verify your information and try again.');
				}
			} else {
				setEmailError(ValidationMessages.email);
			}
		} catch (error) {
			setEmailSendError('There was a problem sending the email. Please verify your information and try again.');
		} finally {
			FormCtx.setIsLoading(false);
		}
	};

	const onSubmit = async (values: IOptIn) => {
		FormCtx.setIsLoading(true);
		updateContactDetails(values);
		const combinedValues = {
			...FormCtx.initialUserData,
			...FormCtx.personalData,
			...FormCtx.spouseData,
			...FormCtx.needsAssessment,
			...values,
			sendPDFByEmail: true
		};
		const result = await FormCtx.upsertFormValues(combinedValues);
		FormCtx.setIsLoading(false);
		if (result !== undefined) {
			window.open('https://app.hellosign.com/s/6wqzQwoV');
		} else {
			alert('error');
		}
		const hasSpousePolicy = PolicyData.spousePolicy.length > 0;
		await oliverAPI.sendApplyEvent({
			applicant_id: FormCtx.applicantId,
			spouse_applicant_id: FormCtx.spouseApplicantId,
			has_spouse_policy: hasSpousePolicy,
			//opt_in: FormCtx.initialUserData?.optIn
		});
	};

	const updateContactDetails = (values: IOptIn) => {
		const email = values.email !== undefined && values.email !== '' ? values.email : FormCtx.initialUserData?.email;
		const sendPDFByEmail = values.sendPDFByEmail !== undefined ? values.sendPDFByEmail : FormCtx.initialUserData?.sendPDFByEmail;

		FormCtx.setInitialUserData({
			...FormCtx.initialUserData,
			email,
			optIn: values.optIn,
			sendPDFByEmail
		});
	};

	const emailSubscriptionOptIn = FormCtx.initialUserData?.optIn;

	const addCIChildData = (item: any) => {
		if (item.product.id === PoliciesIds.ci && hasChildCI) {
			const childCi = quoteData.find((val: any) => val.id === PoliciesIds.cich);
			if (childCi) {
				const totalPremium = childCi.premium + item.product.premium;
				item.product.premium = totalPremium;
				item.product.parameters.childCoverageAmount = childCi.parameters.coverageAmount;
			}
		}

		return item;
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer largeColumn={true} sidebar={emailSubscription()}>
				<h1>Review your quote</h1>
				<div className="card-with-border px-3 mt-3 mb-3">
					<h3 className="pt-3">{FormCtx.initialUserData?.firstName || ''} {FormCtx.initialUserData?.lastName || ''}'s CPA Policy</h3>
					{quoteData.length > 0 && sortProducts(quoteData)
						.filter(f => f.product.id !== PoliciesIds.cich)
						.map((item: any) => {
							const itemProduct = addCIChildData(item);
							item.product = { itemProduct, ...item.product };
							return <PolicyReviewItem
								item={item.product}
								key={`item-${item.index}`}
							/>;
						})}
					<div className="d-flex flex-row justify-content-between px-3 py-3 mt-4 border-top border-top-gray-400">
						<h5 className="mb-0">Total</h5>
						<div className="d-flex align-items-end">
							<h5 className="mb-0 mr-1">${total && total.toFixed(2).toLocaleString()}</h5>
							<h6 className="mb-0">/mo</h6>
						</div>
					</div>
				</div>
				{FormCtx.spouseApplicantId !== undefined && FormCtx.spouseApplicantId !== '' && spouseQuoteData.length > 0 &&
					<div className="card-with-border px-3 mt-4">
						<h3 className="py-3">{FormCtx.spouseData?.spouseFirstName || ''} {FormCtx.spouseData?.spouseLastName || ''}'s CPA Policy</h3>
						{spouseQuoteData.length > 0 && sortProducts(spouseQuoteData).map((item: any) => {
							return <PolicyReviewItem
								item={item.product}
								key={`item-${item.index}`}
							/>;
						})}
						<div className="d-flex flex-row justify-content-between px-3 py-3 mt-4 border-top border-top-gray-400">
							<h5 className="mb-0">Total</h5>
							<div className="d-flex align-items-end">
								<h5 className="mb-0 mr-1">${spouseTotal && spouseTotal.toFixed(2).toLocaleString()}</h5>
								<h6 className="mb-0">/mo</h6>
							</div>
						</div>
					</div>
				}

				<div className="d-flex align-items-start justify-content-between mt-3">
					<div className="d-flex align-items-start">
						<Button className="btn-outline-primary mr-2" onClick={() => history.push(NavRoutes.QuoteSelectPolicy)}>Back</Button>
						<Button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>Apply Now</Button>
					</div>
				</div>
				<div className="d-block d-lg-none mt-lg-0 mt-4">
					{emailSubscription()}
				</div>
			</QuoteContainer>
		</FormProvider>
	);

	function emailSubscription() {
		return (
			<div className="shadow-sm rounded mb-3 p-3">
				{emailSent ||
					<div className="p-2">
						<h4>Get a copy of your quote sent to your email.</h4>
						<div className="form-group">
							<label htmlFor="email">Email</label>
							<Controller
								name="email"
								control={control}
								rules={{
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: ValidationMessages.email
									}
								}}
								render={({ onChange, value }) =>
									<input
										type="text"
										className="form-control"
										id="email"
										name="email"
										value={value}
										onChange={(event) => {
											onChange(event);
											if (emailError !== undefined) { setEmailError(undefined); }
										}}
									/>
								}
							/>
							<ErrorMessage>{errors.email?.message ?? emailError}</ErrorMessage>
						</div>
						{!emailSubscriptionOptIn && !FormCtx.isLoading &&
							<div className="form-group mt-n2">
								<label htmlFor="optIn">Email Subscription</label>
								<Controller
									name="optIn"
									control={control}
									render={({ onChange, value }) =>
										<CheckboxButton
											name="optIn"
											isActive={value === true}
											label="Yes, I would like to receive emails according to my subscription preferences. I understand I can unsubscribe at any time."
											onClick={() => onChange(!value)}
											className="text-left font-weight-normal"
										/>
									}
								/>
							</div>
						}
						<Button
							className={'btn btn-outline-primary btn-block mb-2'}
							disabled={FormCtx.isLoading === true}
							onClick={handleSubmit(handleEmailReport)}
						>
							{FormCtx.isLoading === true ? 'Sending Quote' : 'Send Me My Quote'}
							{FormCtx.isLoading === true && <IconLoad className={'policy-spinner ml-2 spinner-grow-sm spin'} />}
						</Button>
						<ErrorMessage>{emailSendError}</ErrorMessage>
					</div>
				}
				{emailSent &&
					<div className="col-sm-8 col-xl-9 order-sm-first p-4">
						<h3 className="mb-2">Success!</h3>
						<p className="mb-0">Your quote has been sent to</p>
						<span className="text-primary">{FormCtx.initialUserData?.email}</span>
						<p className="mb-0">Check your email to see your personalized report.
						</p>
						<Button className="btn btn-primary mt-2" onClick={handleSubmit(onSubmit)}>Apply Now</Button>
					</div>
				}
			</div>);
	}
};
export default ReviewSelection;
