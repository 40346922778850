import React from 'react';
import { Page } from 'components/common';
import { HaveAQuestion } from 'components/cpaipw';
import classNames from 'classnames';

interface IProps {
	children: any;
	sidebar?: JSX.Element;
	largeColumn?: boolean;
}

export const QuoteContainer: React.FC<IProps> = ( props: IProps ) => {
	const columnClasses = props.largeColumn ? 'col-lg-8' : 'col-lg-6 offset-sm-1 col-sm-10';
	return (
		<Page>
			<div className="container">
				<div className="row">
					<div className={classNames(columnClasses, 'd-flex flex-fill flex-column')}>
						{props.children}
					</div>
					<div className="col-lg-4 d-none d-lg-block position-relative">
						<div className="position-sticky have-a-question d-flex flex-column-reverse">
							<HaveAQuestion />
							{props.sidebar}
						</div>
					</div>
				</div>
			</div>
		</Page>
	);
};
